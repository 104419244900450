import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, Form, Button } from "react-bootstrap"

const IndexPage = () => (
  <Layout>
    <SEO title="Contact Howard Wire" />
    <Container>
      <h1>Contact Howard Wire</h1>
      <Row>
        <Col lg={6}>
          <Form 
            name="contact v1"
            method="post"
            action="/success/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit="submit"
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="contact v1" />
            <p hidden>
            <label>
                Don’t fill this out: <input name="bot-field" />
            </label>
            </p>
              


                <Row>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control 
                                type="text" name="first-name"
                              />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                    <Form.Group>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control 
                                type="text" name="last-name"

                              />
                    </Form.Group>
                    </Col>
                </Row>


                    
                <Form.Group>
                    <Form.Label>Email address</Form.Label>
                    <Form.Control 
                        required
                        type="email" 
                        name="email" 
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Phone</Form.Label>
                    <Form.Control 
                        required
                        type="text" 
                        name="phone" 
                    />
                </Form.Group>
                
                <Form.Group>
                    <Form.Label>Your message</Form.Label>
                    <Form.Control 
                        as="textarea" 
                        name="message"
                        rows="5"
                      />
                </Form.Group>
                <Button type="submit">Submit</Button>
                </Form>
        </Col>
        <Col lg={6}>
          <p>We are located at:</p>
          <p>
            28976 Hopkins Street, Suite A,<br />
            Hayward, CA 94545
          </p>

          <p>You can reach us at:</p>
          <p>
            (800) 969-3559<br />
            (510) 887-8787
          </p>
          <p><a href="mailto:sales@howardwire.com">sales@howardwire.com</a></p>
          <p><strong>We are open</strong></p>
          <p>
            <strong>Monday - Thursday</strong> 7:30am – 4:30pm<br />
            Will Call Hours 7:30am - 4:30pm
          </p>
          <p>
            And on <strong>Friday</strong> 7:30am – 4:00pm <br />
            Will Call 7:30am – 3:00pm
          </p>  
        </Col>
      </Row>
     
    </Container>
    

  </Layout>
)

export default IndexPage
